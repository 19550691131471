<template>
  <m-pdf
    :src="src"
    :color="color"
    :dark="dark"
    :page-no="pageNo"
  />
</template>

<script>
import MPdf from './../../widgets/MPdf/MPdf.vue'
export default {
  name: 'MPdfPage',
  components: {
    MPdf
  },
  props: {
    src: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    pageNo: {
      type: Number,
      default: () => 1
    }
  }
}
</script>
