<template>
  <m-widget-container style="height: 100%;">
    <div
      :key="`pdf_${src}_${nowstamp}`"
      class="m-pdf--container"
      :class="{ 'm-pdf--multi': numPages > 1 }"
    >
      <pdf
        :src="src"
        :page="page"
        @num-pages="numPages = $event; loading = false"
      />
      <v-card-actions
        v-if="numPages > 1"
        class="m-pdf--actions"
      >
        <v-btn
          icon
          :disabled="page === 1"
          @click="page -= 1"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="page === numPages"
          @click="page += 1"
        >
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </m-widget-container>
</template>

<script>
export default {
  name: 'MPdf',
  components: {
    pdf: () => import('vue-pdf')
  },
  props: {
    src: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    pageNo: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      loading: true,
      numPages: 1,
      page: this.pageNo,
      nowstamp: (new Date()).getTime(),
      timer: null
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.nowstamp = (new Date()).getTime()
    }, 60 * 60 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="sass">
  .m-pdf--container
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    margin: auto
    width: 100%
    height: 100%
    padding: 0.5em
    &.m-pdf--multi
      > span
        flex: 1
        flex-grow: 0
        flex-shrink: 1
    &:not(.m-pdf--multi)
      > span
        max-width: 100%
        width: auto
        height: 100%
        canvas
          border-radius: 0.5em
          width: auto!important
          margin: auto
          max-width: 100%
          max-height: 100%
    > .m-pdf--actions
      // position: absolute
      // bottom: 0.5em
      justify-content: center
      width: 100%
</style>
